<template>
  <v-container fluid class="down-top-padding">
    <base-breadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></base-breadcrumb>
    <add-people :dialog="dialog" @close="dialog.show = false" @save="savingPeople()"></add-people>
    <base-card @addModal="openDialog" :heading="page.title" :dialog="true" :manage="true" :button="button">
      <datatable-list
        :text="text"
        :headers="headers"
        :sortBy="sortBy"
        :items="items"
        v-if="fetch"
        @delete="deletePeople"
        :showSelect="showSelect"
        :displayActionItems="displayActionItems"
        :tableUser="true"
        :pagination="pagination"
        :loading="!fetch"
        @update="pagination = { ...pagination, ...$event}"
      ></datatable-list>
      <v-skeleton-loader
        v-for="i in 5"
        v-else
        :key="i"
        type="table-heading"
      ></v-skeleton-loader>
    </base-card>
  </v-container>
</template>

<script>
import { mapMutations } from 'vuex'
import AddPeople from '../../components/forms/AddPeople.vue'
export default {
  components: { AddPeople },
  data: () => ({
    page: {
      title: ''
    },
    dialog: {},
    id: '',
    fetch: false,
    role: '',
    rolesParamsSearch: '',
    school: {},
    breadcrumbs: [
      {
        text: 'Início',
        disabled: false,
        to: '/'
      },
      {
        text: 'Escolas',
        disabled: false,
        to: '/school/',
        exactPath: true
      }
    ],
    button: {
      show: true,
      url: '/school/add',
      text: '...',
      icon: 'mdi-plus'
    },
    text: {
      searchLabel: 'Pesquisar escolas',
      emptyLabel: 'Nenhuma escola encontrada',
      addLabel: 'Adicionar escola',
      addUrl: '/school/add',
      importLabel: 'Importar escolas',
      importUrl: '/school/add'
    },
    headers: [
      {
        text: 'Nome',
        value: 'name',
        align: 'start'
      },
      { text: 'Função', value: 'roles', align: 'center' }

    ],
    sortBy: 'className',
    showSelect: false,
    displayActionItems: false,
    items: [],
    pagination: {
      page: 1,
      itemsPerPage: 15,
      prev_page_url: false,
      next_page_url: false
    }
  }),
  watch: {
    'pagination.page' (oldValue, newValue) {
      if (oldValue !== newValue) {
        this.getPeople()
      }
    }
  },
  methods: {
    ...mapMutations(['setLoading']),
    buildQuery () {
      const pagination = `&page=${this.pagination.page}`
      return pagination
    },
    openDialog () {
      this.dialog.show = true
    },
    savingPeople () {
      this.dialog.show = false
      this.items = []
      this.getPeople()
    },
    formatPeoples (peoples) {
      const arr = []
      if (!peoples.length) return arr
      peoples.forEach(value => {
        if (value.data) {
          const obj = {
            avatar: value.data.user.avatar,
            name: value.data.user.name,
            email: value.data.user.email,
            id: value.data.user.id,
            roles: value.data.roles,
            isSelected: false
          }
          arr.push(obj)
        }
      })
      return arr
    },
    formatPeoplesSend (peoples) {
      const arr = []
      if (!peoples.length) return arr
      peoples.forEach(value => {
        const obj = {
          user: {
            id: value.id
          },
          roles: [
            { name: this.dialog.role, operation: 'REMOVE' }
          ]
        }

        arr.push(obj)
      })
      return arr
    },
    async deletePeople (people) {
      this.setLoading(true)
      const self = this
      const payload = this.formatPeoplesSend(people)
      this.$axios.post(this.dialog.linkSave, { users: payload })
        .then(response => { self.$store.dispatch('alert', { msg: 'Remoção bem sucedida!' }) })
        .catch((error) => {
          const errosArray = Object.assign({}, error.response.data.errors)
          if (error.response.data.errors) {
            Object.keys(errosArray).forEach(function (item) {
              self.$store.dispatch('alert', { color: 'red', msg: errosArray[item][0] })
            })
          } else {
            self.$store.dispatch('alert', { color: 'red', msg: 'Erro na rede, tente novamente!' })
          }
        })
        .finally(() => {
          self.setLoading(false)
          self.getPeople()
        })
    },
    getPeople () {
      this.fetch = false
      this.$axios.get(`/organizations/${this.id}/schools/${this.$route.params.id}/people${this.rolesParamsSearch}${this.buildQuery()}`)
        .then(response => {
          return response.data
        })
        .then(data => {
          this.items = this.formatPeoples(data.items)
          const pagination = data.pagination
          this.pagination = {
            ...this.pagination,
            total: pagination.total,
            page: pagination.current_page,
            itemsPerPage: pagination.per_page
          }
        })
        .finally(() => {
          this.fetch = true
        })
    },
    getSchoolData (organizationId) {
      this.$axios.get(`organizations/${organizationId}/schools/${this.$route.params.id}`)
        .then(res => {
          this.school = res.data
        }).catch(() => {
          const msg = 'Erro na conexão, tente novamente'
          this.$store.dispatch('alert', { color: 'red', msg })
        }).finally(() => {
          this.schoolFetch = true
          this.mountBreadcrumb()
        })
    },
    mountBreadcrumb () {
      this.breadcrumbs.push(
        {
          text: this.school?.data?.name || 'Sem nome definido',
          disabled: false,
          to: `/school/${this.$route.params.id}`
        },
        {
          text: this.page.title,
          disabled: true
        }
      )
    }
  },
  mounted () {
    const id = localStorage.getItem('ACTIVE_ORGANIZATION')
    this.getSchoolData(id)
    let messageIfEmpty = ''

    const rolesParams = this.$route.params.roles
    this.button.url = `/school/people/${this.$route.params.id}/${rolesParams}`

    if (rolesParams === 'coordinators') {
      this.rolesParamsSearch = '?roles[]=SCHOOL_PEDAGOGICAL_COORDINATOR'
      this.role = 'SCHOOL_PEDAGOGICAL_COORDINATOR'
      messageIfEmpty = 'Nenhum coordenador encontrado'
      this.page.title = 'Coordenadores'
    } else if (rolesParams === 'principals') {
      this.rolesParamsSearch = '?roles[]=PRINCIPAL'
      messageIfEmpty = 'Nenhum diretor encontrado'
      this.role = 'PRINCIPAL'
      this.page.title = 'Diretores'
    } else {
      this.page.title = 'Membros da escola'
    }
    this.button.text = this.page.title
    this.text = {
      ...this.text,
      emptyLabel: messageIfEmpty,
      searchLabel: `Pesquisar ${this.page.title}`,
      addLabel: `Adicionar ${this.page.title}`
    }
    this.id = localStorage.getItem('ACTIVE_ORGANIZATION')
    if (this.id) {
      this.getPeople()
    } else {
      this.$store.dispatch('alert', { color: 'red', msg: 'Você precisa selecionar uma organização' })
    }
    this.dialog = {
      show: false,
      linkSave: `/organizations/${this.id}/schools/${this.$route.params.id}/people/sync`,
      role: this.role,
      title: this.page.title
    }
  }
}
</script>
